<template>
    <div class="container-fluid fixed-top p-0 bg-white">
        <div id="topbarTop" class="d-flex justify-content-around align-items-center px-3">
            <div class="d-flex align-items-center text-white d-none d-lg-block">
                <span class="material-icons md-18 me-1">place</span>
                Av. Ipanema, 2258 - Jd. Planalto - Sorocaba/SP
            </div>
            <div class="d-flex align-items-center text-white text-center">
                <span class="material-icons md-18 me-1">shopping_bag</span>
                Faça seu pedido pelo site
            </div>
            <div class="d-flex align-items-center text-white d-none d-lg-block">
                <span class="material-icons md-18 me-1">call</span>
                (15) 98157-2201 | (15) 3327-9932
            </div>
        </div>
        <div id="middlebarTop">
            <div class="container py-4 d-flex justify-content-between align-items-center">
                <span class="material-icons ms-3 md-36 hover" data-bs-toggle="offcanvas" href="#offcanvasMenu" role="button">menu</span>
                <router-link to="/"><img src="@/assets/logo.png" width="140" height="70" class="hover" /></router-link>
                <div class="d-none d-lg-block">
                    <form @submit.prevent="searchProducts()">
                        <div id="searchTop" class="input-group">
                            <input v-model="search" type="text" class="form-control bg-light py-2 no-outline" placeholder="Olá, o que você está procurando ?">
                            <button type="submit" class="btn btn-light d-flex align-items-center no-outline">
                                <span class="material-icons text-dark">search</span>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="d-flex justify-content-center d-none d-md-block">
                    <a href="https://wa.me/5515981572201?text=Olá,%20Acessei%20o%20site%20da%20Jujuba%20Fest" target="_blank"><img src="@/assets/whatsapp.png" width="40" height="40" /></a>
                    <a href="https://www.facebook.com/jujuba.fest21/" target="_blank"><img src="@/assets/facebook.png" class="mx-3" width="40" height="40" /></a>
                    <a href="https://www.instagram.com/jujuba.fest/" target="_blank"><img src="@/assets/instagram.png" width="40" height="40" /></a>
                </div>
                <div class="position-relative d-flex align-items-center me-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasCart" aria-controls="offcanvasRight">
                    <span class="material-icons md-36 hover">shopping_cart</span>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger text-white">{{ cartSize }}</span>
                </div>
            </div>
        </div>
        <div id="navbarTop" class="container-fluid d-flex justify-content-center align-items-center">
            <span @click="moveLeft()" class="material-icons md-24 hover text-white mx-auto no-select">arrow_back</span>
            <div ref="itemsNavbar" class="container overflow-auto d-flex justify-content-between">
                <router-link class="router-link" to="/"><div class="item-navbar py-2 px-3 text-white">Início</div></router-link>
                <router-link class="router-link" to="/promocoes"><div class="item-navbar py-2 px-3 text-warning">Promoções</div></router-link>
                <router-link class="router-link" to="/doces"><div class="item-navbar py-2 px-3 text-white">Doces</div></router-link>
                <router-link class="router-link" to="/chocolates"><div class="item-navbar py-2 px-3 text-white">Chocolates</div></router-link>
                <router-link class="router-link" to="/biscoitos"><div class="item-navbar py-2 px-3 text-white">Biscoitos</div></router-link>
                <router-link class="router-link" to="/salgadinhos"><div class="item-navbar py-2 px-3 text-white">Salgadinhos</div></router-link>
                <router-link class="router-link" to="/descartaveis"><div class="item-navbar py-2 px-3 text-white">Descartáveis</div></router-link>
                <router-link class="router-link" to="/utensilios"><div class="item-navbar py-2 px-3 text-white">Utensílios</div></router-link>
                <router-link class="router-link" to="/decoracoes"><div class="item-navbar py-2 px-3 text-white">Decorações</div></router-link>
                <router-link class="router-link" to="/baloes"><div class="item-navbar py-2 px-3 text-white">Balões</div></router-link>
                <router-link class="router-link" to="/confeitaria"><div class="item-navbar py-2 px-3 text-white">Confeitaria</div></router-link>
                <router-link class="router-link" to="/embalagens"><div class="item-navbar py-2 px-3 text-white">Embalagens</div></router-link>
                <router-link class="router-link" to="/bebidas"><div class="item-navbar py-2 px-3 text-white">Bebidas</div></router-link>
                <router-link class="router-link" to="/brinquedos"><div class="item-navbar py-2 px-3 text-white">Brinquedos</div></router-link>
                <router-link class="router-link" to="/vestimentas"><div class="item-navbar py-2 px-3 text-white">Vestimentas</div></router-link>
                <router-link class="router-link" to="/cosmeticos"><div class="item-navbar py-2 px-3 text-white">Cosméticos</div></router-link>
                <router-link class="router-link" to="/diversos"><div class="item-navbar py-2 px-3 text-white">Diversos</div></router-link>
                <router-link class="router-link" to="/painel"><div class="item-navbar py-2 px-3 bg-white text-dark">Painel</div></router-link>
            </div>
            <span @click="moveRight()" class="material-icons md-24 hover text-white mx-auto no-select">arrow_forward</span>
        </div>

        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMenu">
            <div class="offcanvas-header d-flex justify-content-start border-bottom">
                <span class="material-icons mx-3 md-36 hover" data-bs-toggle="offcanvas" href="#offcanvasMenu" role="button">menu</span>
                <h6 class="offcanvas-title">Menu</h6>
            </div>
            <div class="offcanvas-body px-0 py-3">
                <div class="d-block d-lg-none border-bottom pb-3 mb-3">
                    <form @submit.prevent="searchProducts()">
                        <div id="searchMenuTop" class="input-group mx-3">
                            <input v-model="search" type="text" class="form-control bg-light py-2 no-outline border" placeholder="Olá, o que você está procurando ?">
                            <button type="submit" class="btn btn-light d-flex align-items-center no-outline border">
                                <span class="material-icons text-dark">search</span>
                            </button>
                        </div>
                    </form>
                </div>
                <h6 class="fw-bold text-center">Categorias</h6>
                <ul class="list-group mt-3">
                    <li @click="goTo('/')" class="list-group-item px-5 border-0 itemMenu hover">Início</li>
                    <li @click="goTo('/promocoes')" class="list-group-item px-5 border-0 itemMenu hover">Promoções</li>
                    <li @click="goTo('/doces')" class="list-group-item px-5 border-0 itemMenu hover">Doces</li>
                    <li @click="goTo('/chocolates')" class="list-group-item px-5 border-0 itemMenu hover">Chocolates</li>
                    <li @click="goTo('/biscoitos')" class="list-group-item px-5 border-0 itemMenu hover">Biscoitos</li>
                    <li @click="goTo('/salgadinhos')" class="list-group-item px-5 border-0 itemMenu hover">Salgadinhos</li>
                    <li @click="goTo('/descartaveis')" class="list-group-item px-5 border-0 itemMenu hover">Descartáveis</li>
                    <li @click="goTo('/utensilios')" class="list-group-item px-5 border-0 itemMenu hover">Utensílios</li>
                    <li @click="goTo('/decoracoes')" class="list-group-item px-5 border-0 itemMenu hover">Decorações</li>
                    <li @click="goTo('/baloes')" class="list-group-item px-5 border-0 itemMenu hover">Balões</li>
                    <li @click="goTo('/confeitaria')" class="list-group-item px-5 border-0 itemMenu hover">Confeitaria</li>
                    <li @click="goTo('/embalagens')" class="list-group-item px-5 border-0 itemMenu hover">Embalagens</li>
                    <li @click="goTo('/bebidas')" class="list-group-item px-5 border-0 itemMenu hover">Bebidas</li>
                    <li @click="goTo('/brinquedos')" class="list-group-item px-5 border-0 itemMenu hover">Brinquedos</li>
                    <li @click="goTo('/vestimentas')" class="list-group-item px-5 border-0 itemMenu hover">Vestimentas</li>
                    <li @click="goTo('/cosmeticos')" class="list-group-item px-5 border-0 itemMenu hover">Cosméticos</li>
                    <li @click="goTo('/diversos')" class="list-group-item px-5 border-0 itemMenu hover">Diversos</li>
                    <li @click="goTo('/painel')" class="list-group-item px-5 border-0 itemMenu hover">Painel</li>
                </ul>

                <h6 class="fw-bold text-center py-3 mt-3 border-top">Contatos</h6>
                <div class="d-flex justify-content-center">
                    <a href="https://wa.me/5515981572201?text=Olá,%20Acessei%20o%20site%20da%20Jujuba%20Fest" target="_blank"><img src="@/assets/whatsapp.png" width="25" height="25" /></a>
                    <a href="https://www.facebook.com/jujuba.fest21/" target="_blank"><img src="@/assets/facebook.png" class="mx-3" width="25" height="25" /></a>
                    <a href="https://www.instagram.com/jujuba.fest/" target="_blank"><img src="@/assets/instagram.png" width="25" height="25" /></a>
                </div>
                <h6 class="text-center mt-3">(15) 98157-2201</h6>
                <h6 class="text-center">(15) 3327-9932</h6>
                <h6 class="text-center">contato@jujubafest.com.br</h6>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            search: ''
        }
    },

    computed: {
        ...mapGetters({
            cartSize: 'getCartSize'
        })
    },

    methods: {
        async searchProducts() {
            await localStorage.setItem('_search', this.search)
            window.location.href = '/buscar'
        },

        goTo(page) {
            window.location.href = page
        },

        moveLeft() {
            this.$refs.itemsNavbar.scrollLeft -= 50
        },

        moveRight() {
            this.$refs.itemsNavbar.scrollLeft += 50
        }
    }
}
</script>

<style>
#offcanvasMenu{
    width: 280px;
    background: #ffffff;
}

.itemMenu:hover{
    background: #ffbb00;
}

#topbarTop{
    background: #5fa437; 
}

#middlebarTop{
    background: #fece02;
}

#navbarTop{
    background: #e91876;
}

#searchTop{
    width: 450px;
}

.item-navbar:hover{
    cursor: pointer;
    background: #423c3f;
    transition-duration: 0.7s;
}

#searchMenuTop{
    width: 240px;
}

.no-select{
    user-select: none;
}
</style>